export class CONSTANTS {
  public static DECIMAL_REGEX = /^[0-9]\d*(\.\d+)?$/
  public static OPTION = {
    YES: 1,
    NO: 0
  };
  public static STATUS_CODE = {
    OK: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
  };
  public static LANG_CODE = {
    MALAYSIA: 'ms',
    ENGLISH: 'en',
  };

  public static MESSAGES = {
    COMFIRM_HEADER: 'Confirmation',
    COMFIRM_MAKE_ORDER: 'Place Order',
    COMFIRM_CANCEL_ORDER: 'Cancel Order',
    CONFIRM_SUBMIT: "Are you sure you want to submit?",
    ALERT_ERROR_HEADER: 'Error',
    LOGOUT_CONFIRMATION: 'Are you sure want to logout?',
    COMFIRM_PROCESS_TO_NEXT_STEP: 'Process to next step?',

    GARAGE_CONFIRM_CANCEL_BOOKING: 'Are you sure want to cancel this task?',
    GARAGE_CONFIRM_APPROVE_BOOKING: 'Are you sure want to approve this task?',
    GARAGE_CONFIRM_REJECT_BOOKING: 'Are you sure want to reject this task?',
    GARAGE_CONFIRM_CAR_ARRIVED_AT_GARAGE: 'Car arrived at garage?',
    GARAGE_CONFIRM_PAID_AT_COUNTER: 'Are you sure you want to confirm?',
    MAKE_ORDER_COMFIRMATION: 'Are you sure you want to Place this order?',
    CANCEL_ORDER_COMFIRMATION: 'Are you sure you want to cancel this order?'
  };

  public static BOOKING_STATUS = {
    PENDING: { value: 'pending', name: 'Pending', order: 1, isAutoUpdate: true },
    APPOINTMENT_CONFIRMED: { value: 'appointment_confirmed', name: 'Appointment Confirmed', order: 2 },
    APPOINTMENT_CANCELLED: { value: 'appointment_cancelled', name: 'Appointment Cancelled', order: 3, isStop: true },
    APPOINTMENT_REJECTED: { value: 'appointment_rejected', name: 'Appointment Rejected', order: 4, isStop: true },
    PICK_UP_VEHICLE: { value: 'pick_up_vehicle', name: 'Pick-up Vehicle', order: 5 },
    VEHICLE_ARRIVED_GARAGE: { value: 'vehicle_arrived_garage', name: 'Vehicle Arrived Garage', order: 6 },
    VEHICLE_CHECKING: { value: 'vehicle_checking', name: 'Vehicle Checking', order: 7 },
    QUOTATION_GENERATED: { value: 'quotation_generated', name: 'Quotation Generated', order: 8, isAutoUpdate: true },
    QUOTATION_REJECTED: { value: 'quotation_rejected', name: 'Quotation Rejected', order: 9, isStop: true, isAutoUpdate: true },
    QUOTATION_APPROVED: { value: 'quotation_approved', name: 'Quotation Approved', order: 10, isAutoUpdate: true },
    PROCEED_REPAIR: { value: 'proceed_repair', name: 'Proceed Repair', order: 11 },
    JOB_DONE: { value: 'job_done', name: 'Job Done', order: 12 },
    INVOICE_GENERATED: { value: 'invoice_generated', name: 'Invoice Generated', order: 13, isAutoUpdate: true },
    LPO_SUBMITTED: { value: 'lpo_submitted', name: 'LPO Submitted', order: 14, isAutoUpdate: true }, // Business Only, Customer upload LPO via CMS
    PAYMENT_RECEIVED: { value: 'payment_received', name: 'Payment Received', order: 15 },
    CAR_DELIVERING: { value: 'car_delivering', name: 'Car Delivering', order: 16 },
    CAR_DELIVERED: { value: 'car_delivered', name: 'Car Delivered', order: 17 },
    GOVALET_JOB_ACCEPTED: { value: 'govalet_job_accepted', name: 'GoValet Job Accepted', order: 18 }, // update from GoValet
    GOVALET_ARRIVED_AT_LOCATION: { value: 'govalet_arrived_at_location', name: 'Arrived At Location', order: 19 }, // update from GoValet
    COMPLETED: { value: 'completed', name: 'Completed', order: 20 }
  };

  public static ALL_OPTION = undefined;
  public static BOOKING_TYPE = {
    BUSINESS: 'business',
    NORMAL: 'normal'
  };
  public static BOOKING_TYPES = [
    { value: 'business', name: 'Business' },
    { value: 'normal', name: 'Normal' },
  ];

  public static TASK_TYPE = {
    IN_PROGRESS: 'inprogress',
    TODAY: 'today',
    PENDING_APPROVAL: 'pending',
    CONFIRMED: 'confirmed'
  };

  public static BOOKING_STATUS_FILTER = [
    { value: 'vehicle_arrived_garage', name: 'Vehicle arrived at Garage', checked: false },
    { value: 'vehicle_checking', name: 'Vehicle Checking', checked: false },
    { value: 'quotation_generated', name: 'Quotation Generated', checked: false },
    { value: 'quotation_pending_approval', name: 'Quotation Pending for Approval', checked: false },
    { value: 'quotation_rejected', name: 'Quotation Rejected', checked: false },
    { value: 'quotation_approved', name: 'Quotation Approved', checked: false },
    { value: 'proceed_repair', name: 'Proceed Repair', checked: false },
    { value: 'job_done', name: 'Job Done', checked: false },
    { value: 'payment_received', name: 'Payment Received', checked: false },
    { value: 'invoice_generated', name: 'Invoice Generated', checked: false },
    { value: 'invoice_pending_approval', name: 'Invoice Pending for Approval', checked: false },
    { value: 'invoice_approved', name: 'Invoice Approved', checked: false },
    { value: 'invoice_rejected', name: 'Invoice Rejected', checked: false },
    { value: 'lpo_submitted', name: 'LPO Submitted', checked: false },
    { value: 'car_delivering', name: 'Car Delivering', checked: false },
    { value: 'car_delivered', name: 'Car Delivered', checked: false },
    { value: 'car_collection_pending', name: 'Car Collection Pending', checked: false },
    { value: 'car_collected', name: 'Car Collected', checked: false },
    { value: 'completed', name: 'Completed', checked: false }
  ];
  public static USER_MERCHANT = 'user_merchant';
  public static DISCOUNT_TYPE = {
    PERCENT: { value: 'percent', name: 'Percentage' },
    AMOUNT: { value: 'amount', name: 'Fixed Price' },
  };

  public static TIME_SLOT_SESSION = {
    MORNING: { value: 'morning', name: 'Morning' },
    AFTERNOON: { value: 'afternoon', name: 'Afternoon' }
  };
}
